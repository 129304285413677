.circle-diagram-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.circle-diagram-wrapper h4 {
  color: #5d5c5e;
  font-family: DM Sans;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.circles-container {
  height: 220px;
  width: 250px;
  position: relative;
}

.distribution-circle-outer {
  position: absolute;
  width: 160px;
  height: 160px;
  margin-top: 1.8rem;
  margin-left: 4.1rem;
  border-radius: 50%;
  top: -10px;
  left: -24px;
  z-index: 10;
  background-color: red;
  filter: drop-shadow(2px 2px 16px rgba(0, 0, 0, 0.25));
}

.distribution-circle-center {
  position: absolute;
  width: 85px;
  height: 85px;
  margin-top: 1.5rem;
  margin-left: 3.8rem;
  top: 32px;
  left: 18px;
  border-radius: 50%;
  z-index: 15;
  background-color: #fff;
}

.span-wrapper {
  margin-left: 1rem;
}

.span-wrapper span {
  position: absolute;
  color: #4e4e4e;
  font-family: DM Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: right;
  z-index: 1000;
  text-align: center;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 60px;
}

.percentage {
  height: 7px;
  width: 7px;
  display: inline-block;
  margin-bottom: 0.1rem;
  margin-right: 0.25rem;
  border-radius: 100px;
}