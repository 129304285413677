/*
  Note that these are only used for syntax highlighting
  and fallback, the actual values are set in src/theme.js
*/
:root {
    --color-primary: #068466;
    --color-primary-light: #00af85;
    --color--primary-dark: #116b56;
    --color-secondary: #eeb862;
    --color-secondary-light: #fcc265;
    --color-secondary-dark: #daa654;
    --color-3: #005450;
    --color-3-light: #007f79;
    --color-3-dark: #084441;
    --color-4: #2d2a35;
    --color-4-light: #403b4e;
    --color-4-dark: #29272f;
    --color-error-message: #ff0c0c;
}

#root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex: 1;
    width: 100%;
}

.dc-title:after {
    content: '.';
    color: var(--color-secondary);
}

.dc-logo {
    height: 30px;
}

button.big {
    padding: 18px 54px;
}

button.big > span {
    font-size: 14px;
}

.no-text-decoration {
    text-decoration: none;
}

.align-right {
    text-align: right;
}
