.employees-wrapper {
  font-size: 18px;
  width: 100%;
}

.employees-toolbar {
  width: 100%;
  height: 81px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #ccc;
}

.list-style {
  list-style-type: none;
  padding: 0;
  padding-left: 0.5rem; 
}

.export-button-alignment {
  display: flex;
  justify-content: right;
  margin-top: 1rem;
}

.close-invite-many-modal-button {
  outline: 0;
  border: none;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 50px;
  background-color: #eeb862;
  color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.checkbox-invite-many-modal-button {
  outline: 0;
  border: none;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 50px;
  background-color: #068466;
  color: #2b2b2b;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.client-rights-circular-progress-style {
  margin-right: 5rem;
}

.invite-many-checkbox-style {
  color: #fff;
  border-radius: 50px;
}

.invite-success-style {
  padding-left: 0.5rem;
}

.close-invite-many-modal-button:hover {
  background-color: rgb(237, 165, 51);
}

.employees-title {
    font-size: 1.6em;
    letter-spacing: 0.04rem;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'DM Serif Display', serif;
    font-weight: bold;
    margin-left: 0rem;
}

.invalid-email-display-container h4 {
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.invalid-email-display-container p {
  padding: 0;
  margin: 0;
}

.client-modal-checkbox-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.employees-table {
  padding: 10px;
}

.clickable {
  cursor: pointer;
  transition: background-color 250ms ease;
}

.clickable:active {
  background-color: #ddd !important;
}

.clickable:hover {
  background-color: #eeeeee;
}

@media screen and (min-width: 600px) {
  .employees-wrapper {
    padding: 0;
  }
}

.employee-modal-wrapper {
  padding: 10px;
  position: fixed;
  width: 90%;
  max-width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#employee-modal-modal-title {
  font-family: DM Serif Display, serif;
}

.employee-modal-form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
}

.buttons {
  display: flex;
  margin-top: 40px;
}

.buttons > * {
  margin-left: 10px;
}

.buttons .cancel {
  box-shadow: none;
}

.employee-modal-form .MuiTextField-root {
  margin-top: 20px;
}

@media screen and (min-width: 600px) {
  .employee-modal-wrapper {
    padding: 20px;
  }
}

.employees-toolbar-back {
  box-shadow: none;
}

.employee-error-button {
  color: white;
  border-color: white;
  margin: auto;
}

.employee-details {
  margin: 20px;
  padding: 20px;
}

.employee-details-section {
  margin-bottom: 20px;
}

.upload-employees {
  float: right;
}

.table-cell-container-checkbox {
  padding-right: 0;
}
.table-cell-container-name {
  padding-left: 0;
}

.add-button-style {
  margin-right: 0.5rem;
}

.email-column-style {
  text-align: center;
}

.table-head-style {
  font-weight: bold;
  font-size: 0.95rem;
}

/* Checkbox styling */

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  margin-top: 0.2rem;
}

.checkbox > input {
  height: 17.5px;
  width: 17.5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #000;
  border-radius: 2px;
  outline: none;
  transition-duration: 0.2s;
  background-color: #fff;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid var(--color-primary-dark);
  background-color: var(--color-primary-light);
}

.checkbox > input:checked + span::after {
  content: "\2714";
  display: block;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0.45rem;
  top: 0.16rem;
}

.checkbox-parent > input:checked + span::after {
  margin-top: 0.12rem;
}

.checkbox > .employee-checkbox-style:checked + span::after {
  top: 0rem;
}

.checkbox > input:active {
  border: 2px solid var(--color-primary-dark);
}

/* Card and text in dialog */
.dialog-card-style {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 2rem 0 2rem;
}

.dialog-name-style {
  font-weight: bold;
  padding: 0 0 0.5rem 0;
}

.dialog-client-name-style {
  font-weight: bold;
  padding: 0.5rem 1rem;
  text-align: center;
}

.dialog-name-style:first-child {
  padding-top: 0.5rem;
}

#alert-dialog-title .MuiTypography-h6 {
  letter-spacing: 0.125rem;
  text-align: center;
}
