.data-grid-style {
    background-color: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.40);
    margin-bottom: 10px;
}

.MuiDataGrid-root .MuiDataGrid-colCellTitle {
    font-weight: bold;
}

.MuiDataGrid-cell {
    cursor: pointer;
}

.MuiDataGrid-colCellSortable {
    cursor: auto !important;
}
