.counseling-modal-wrapper {
    padding: 10px;
    position: fixed;
    width: 90%;
    max-width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#counseling-modal-title {
    font-family: DM Serif Display, serif;
}

.counseling-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
}

.counseling-modal-buttons {
    display: flex;
    margin-top: 40px;
}

.counseling-modal-buttons > * {
    margin-left: 10px;
}

.counseling-modal-buttons .cancel {
    box-shadow: none;
}

.counseling-form .MuiTextField-root {
    margin-top: 20px;
}

@media screen and (min-width: 600px){
    .counseling-modal-wrapper {
        padding: 20px;
    }
}
