.top-bar {
    box-shadow: none;
    background-color: var(--color-4);
}

.language-picker-button {
    color: white;
    margin-right: 20px;
    box-shadow: none;
}

.top-bar-menu-button {
    color: white;
}

@media screen and (max-width: 600px) {
    .language-picker-button {
        min-width: 0;
        margin-right: 0;
    }

    .top-bar-menu-button {
        min-width: 0;
    }

    button .MuiButton-startIcon {
        margin: auto;
    }
}
