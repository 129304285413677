.filtered-chart {
  position: absolute;
  right: 40px;
  z-index: 1;
  width: 95%;
  height: auto;
}

.legend-dot {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -6px;
  top: 0;
  z-index: 2000;
}

.legend-dot p {
  color: #666;
  margin-right: 9px;
  font-size: 12px;
  line-height: 15px;
}

.dot {
  height: 16px;
  width: 16px;
  background-color: rgb(6, 132, 102);
  border-radius: 50%;
  display: inline-block;
}

.shadow {
  -webkit-filter: drop-shadow( 0 0.2px 0.2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow( 0 0.2px 0.2px rgba(0, 0, 0, 0.5));
}