.MuiDrawer-paper {
  width: 239px;
  height: 100%;
  background: url("../../assets/newBackground.jpg") rgba(0, 0, 0, 0.65);
  background-size: cover;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
}

.navigation-wrapper {
  width: 100%;
  display: flex;
}

.navigation-drawer {
  width: 239px;
  flex-shrink: 0;
}

.main-content {
  flex-grow: 1;
}

.navigation-welcometext {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  color: #fff;
  font-weight: bold;
  font-size: 1.45rem;
  letter-spacing: 0.125rem;

}

.navigation-list {
  margin: auto;
  margin-left: 15px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.navigation-icon-style {
  color: var(--color-secondary);
  padding-bottom: 0.1rem;
}

.MuiListItem-gutters {
  border-radius: 5px;
}

.navigation-text {
  color: #fff;
  transition: all 0.1s ease-in-out;
}

.navigatiom-link-hover {
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

.navigation-link-hover:hover {
  background: rgba(0, 0, 0, 0.25);
}

.navigation-link-hover:hover .navigation-text {
  transform: scale(1.05);
}
.navigation-logout-text {
  transition: all 0.1s ease-in-out;
  color: #fff;
}

.navigation-logout-hover {
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

.navigation-logout-hover:hover {
  background: rgba(255, 255, 255, 0.1);
}

.navigation-logout-hover:hover .navigation-logout-text {
  transform: scale(1.05);
}
