.cycles-wrapper {
  font-size: 18px;
  width: 100%;
}

.cycles-toolbar {
  width: 100%;
  height: 81px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #ccc;
}

.cycles-title {
  font-size: 1.6em;
  letter-spacing: 0.04rem;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'DM Serif Display', serif;
  font-weight: bold;
  margin-left: 0rem;
}

.cycles-table-header {
  font-weight: bold;
}

.cycles-table {
  padding: 10px;
}

.clickable {
  cursor: pointer;
  transition: background-color 250ms ease;
}

.clickable:active {
  background-color: #ddd !important;
}

.clickable:hover {
  background-color: #eeeeee;
}

@media screen and (min-width: 600px) {
  .cycles-wrapper {
    padding: 0;
  }
}

.cycle-details,
.cycle-edit-wrapper {
  margin: 20px;
  padding: 20px;
}

.cycle-details-section,
.cycle-edit-wrapper > * {
  margin-bottom: 20px;
}

.cycle-edit-survey-select {
  min-width: 300px;
}

.cycle-edit-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.send-email {
  float: right;
}

.cycles-link-style {
  text-decoration: none;
}

.cycle-input-style {
  color: #f44336;
  font-size: 14px;
  font-family: "Roboto, Helvetica, Arial, sans-serif"
}

.delete-alert-style, .reinforced-delete-alert-style {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.10rem;
  margin-top: 2rem;
}


.reinforced-delete-alert-style {
  color: #f44336;
  position: relative;
  transition: all 250ms ease;
}

.deny-delete-accept-style::before {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #f44336;
  transform: rotate(-45deg);
  position: absolute;
  border-radius: 5px;
}

.deny-delete-accept-style::after {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #f44336;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 5px;
}
