.cycles-wrapper {
  font-size: 18px;
  width: 100%;
  min-height: calc(100vh - 64px);
}

.toolbar-content-wrapper {
  width: 100%;
  padding: 0rem 2rem 0rem 2rem;
  margin-top: 1rem;
}

.export-options-container {
  float: right;
}

.select-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: DM Sans;
  font-weight: 700;
}

.select-text-container p {
  margin-right: 0.3rem;
  color: #3d4857;
}

.select {
  border: none;
  background-color: transparent;
  font-family: DM Sans;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-right: 2rem;
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-appearance: none;
}

.option {
  font-family: DM Sans;
  font-weight: 700;
  color: #2b2b2b;
  border: none;
}

.export-button {  
  background: transparent;
  outline: none;
  border: none;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}

.company-tabs {
  width: 100%;
  padding-left: 3rem;
  background-color: #fff;
}

.company-result-details {
  min-height: calc(100vh - 247px);
}

.company-result-inner-content {
  padding: 54px 71px 0 71px;
}

.image-print-container {
  position: absolute;
  bottom: 0px;
  right: 80px;
}

.image-questions-print-container {
  margin-top: 1rem;
}

@media only screen and (max-width: 1330px) {
  .company-result-details.company-result-inner-content {
    padding: 54px 71px 54px 71px;
  }
}

@media only screen and (max-width: 600px) {
  .cycles-wrapper {
    min-height: calc(100vh - 56px);
  }

  .company-result-details {
    min-height: calc(100vh - 239px);
  }
}