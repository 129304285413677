.login-title {
    text-align: center;
    position: relative;
    width: 100%;
    top: -59px;
    padding: 44px 0 73px;
    background: var(--color-primary);
    border-radius: 3px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.12), 0 5px 15px rgba(0, 0, 0, 0.5);
    margin-bottom: -20px;
    color: white;
}

.login-form {
    padding: 20px;
    background: white;
    border-radius: 3px;
    margin: 50px 10px 10px 10px;
}

.login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-text-field {
    max-width: 280px;
    margin: 0 60px;
}
