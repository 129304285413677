.counseling-text-modal-wrapper {
    padding: 10px 1rem 10px 1rem;
    position: fixed;
    width: 90%;
    max-width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#counseling-text-modal-title {
    font-family: DM Serif Display, serif;
}

.counseling-text-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
}

.counseling-text-modal-buttons {
    display: flex;
    margin-top: 40px;
}

.counseling-text-modal-buttons > * {
    margin-left: 10px;
}

.counseling-text-modal-buttons .cancel {
    box-shadow: none;
}

.counseling-text-form .MuiTextField-root {
    margin-top: 20px;
}

@media screen and (min-width: 600px){
    .counseling-text-modal-wrapper {
        padding: 20px 30px;
    }
}
