.automatic-analysis-wrapper {
    margin-top: 40px;
}

.automatic-analysis-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
}

.automatic-analysis-dropdown-label {

    color: #3d4857; 
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    letter-spacing: 0.075rem;
    padding: 0 1rem;
    width: 75%;
 
}

.automatic-analysis-export-container {
    margin-top: 2rem;
}

.automatic-analysis-insufficient-search-paragraph {
    margin: 1rem 0 0 0;
    font-size: 0.9rem;
    font-family: 'DM Sans', sans-serif;
    line-height: 20px;
    font-weight: bold;
    color: var(--color-error-message);
}

