.admins-wrapper {
    font-size: 18px;
    width: 100%;
}

.admins-toolbar {
    width: 100%;
    height: 81px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.admins-title {
    font-size: 36px;
    font-weight: normal;
}

.admins-table {
    padding: 10px;
}

.clickable {
    cursor: pointer;
    transition: background-color 250ms ease;
}

.clickable:active {
    background-color: #ddd !important;
}

.clickable:hover {
    background-color: #eeeeee;
}
