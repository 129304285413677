/* Section Title Area */
.section-title {
  font-size: 1.8em;
  font-weight: bold;
  letter-spacing: 0.04rem;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 0px;
}
.section-toolbar {
  width: 100%;
  height: 81px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

/* Section Main Content Area */
/* (Section: Company, Survey, Modules) */
.section-table-layout {
  padding: 10px;
}
.section-table-heading {
  font-weight: bold;
}

/* (Section: Terms, Library) */
.section-SingleSelectionGrid-layout {
  margin: 10px;
}

.section-detail-layout {
  padding: 20px;
  margin: 10px;
}

.section-detail-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    padding-bottom: 0.2rem;
}

.section-detail-header {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    padding-bottom: 0.1rem;
}
