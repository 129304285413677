.text-form {
  margin-bottom: 15px;
}

.img-wrapper {
  display: flex;
  justify-content: center;
}

.upload-button-container {
  position: relative;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.foreground {
  z-index: 1;
  position: absolute;
  top: 75%;
  right: 10%;
}

@media screen and (max-width: 1100px){
  .foreground {
    top: 77.5%;
    right: 15%;
  }
}

@media screen and (max-width: 1060px){
  .foreground {
    top: 72.5%;
    right: 20%;
  }
}

@media screen and (max-width: 800px){
  .foreground {
    top: 70%;
    right: 25%;
  }
}

@media screen and (max-width: 600px){
  .foreground {
    top: 75%;
    right: 10%;
  }
}