.MuiFormLabel-root,
.MuiInputBase-root {
    font-family: 'DM Sans', sans-serif
}

.MuiTypography-h6 {
    font-family: 'DM Serif Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
}

.MuiButton-root {
    font-family: 'DM Sans', sans-serif;
    border-radius: 2px;
    font-weight: 500;
    box-shadow: 0 2px 2px rgba(244, 67, 54, 0.14);
    font-size: 0.75rem;
}

.MuiTypography-body1 {
  letter-spacing: 0.045rem;
}
