.surveys-wrapper {
    font-size: 18px;
    width: 100%;
}

[contenteditable="true"].section-title br {
    display:none;
}

.clickable {
    cursor: pointer;
    transition: background-color 250ms ease;
}

.clickable:active {
    background-color: #ddd !important;
}

.clickable:hover {
    background-color: #eeeeee;
}

.survey-paragraph-style {
    font-weight: bold;
}

.survey-question {
    margin: 10px;
    padding: 10px;
}

.survey-question-language {
    font-weight: bold;
    margin-bottom: 0;
}

.survey-question-text {
    margin-top: 0;
}

.survey-question-category {
    margin-left: 15px;
    display: flex;
}

.survey-question-weight-categories {
    margin: 20px 0;
    display: block;
}

.survey-form-header {
    display: flex;
}

.survey-form-weight {
    max-width: 80px;
    margin-right: 20px;
}

button.error {
    color: #d32f2f;
}

.survey-question-warning {
  position: absolute;
  box-shadow: 5px 5px 25px 20px rgba(224,217,217,.75);
  right: 2rem;
  top: 65%;
  margin-top: 15rem;
  padding: 1rem;
  border-radius: 5px;
  color: #d32f2f;
  margin-left: 2rem;
}

.survey-question-warning-text {
  text-align: center;
  font-size: 1.2rem;
  display: flex;
}

@media screen and (min-width: 600px) {
    .surveys-wrapper {
        padding: 0;
    }
    .surveys-title {
        margin-left: -8px;
    }
}

.surveys-link-style {
  text-decoration: none;
}

.minified {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: 6rem;
}
