.filtered-cards-grid-container {
  flex-grow: 1;
}

.filtered-analysis-details {
  flex-grow: 1;
  padding: 0 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.filter-grid-container {
  width: 96%;
  margin: auto;
  flex-wrap: nowrap;
}

.filtered-chart-container {
  width: 100%;
  position: relative;
  min-height: 300px;
  height: 100%;
}

.chart-details-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  color: #2d2a34;
  margin-top: 16px;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.to-few-results {
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-statistic-information {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.circle-statistic-information p {
  text-align: left;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  color: #2d2a34;
  margin-top: 16px;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
}

.circle-info-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 1rem;
}

.circle-info-wrapper span {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  margin-right: 2rem;
  align-items: center;
}

.red-dot {
  background-color: rgb(6, 132, 102);
}

.yellow-dot {
  background-color: #FDCF5B;
}

.green-dot {
  background-color: rgb(238, 115, 98);
}

.red-dot,
.yellow-dot,
.green-dot {
  height: 8px;
  width: 8px;
  border-radius: 100px;
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: 0.0925rem;
  top: 50%;
}

.circles-wrapper {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
}
