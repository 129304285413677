.terms-wrapper {
    font-size: 18px;
    width: 100%;
}

[contenteditable="true"].section-title br {
    display:none;
}

.clickable {
    cursor: pointer;
    transition: background-color 250ms ease;
}

.clickable:active {
    background-color: #ddd !important;
}

.clickable:hover {
    background-color: #eeeeee;
}

.survey-question {
    margin: 10px;
    padding: 10px;
}

.survey-question-language {
    font-weight: bold;
    margin-bottom: 0;
}

.survey-question-text {
    margin-top: 0;
}

.survey-question-weight-categories {
    margin: 20px 0;
    display: block;
}

.survey-form-header {
    display: flex;
}

.survey-form-text {
    margin-bottom: 10px;
}

.survey-form-weight {
    max-width: 80px;
    margin-right: 20px;
}

button.error {
    color: #d32f2f;
}

@media screen and (min-width: 600px) {
    .surveys-wrapper {
        padding: 0;
    }
    .section-title {
        margin-left: 0px;
    }
}

.surveys-link-style {
    text-decoration: none;
}