.filtering-container {
  background-color: #fff;
  margin-top: 2rem;
  margin-right: 1rem;
  display: flex;
}

.filtering-container p {
  font-size: 1rem;
  font-family: 'DM Sans';
  line-height: 20px;
  font-weight: bold;
  color: #3d4857;
  margin-right: 0.3rem;
}

.filter-select {
  border: none;
  background-color: transparent;
  min-width: 6rem;
  cursor: pointer;
  outline: none;
  color: var(--color-primary);
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 0;
  -moz-appearance: none;
}

.filter-select-option {
  font-weight: 500;
  color: #3d4857;
  border: none;
  font-size: 14px;
}