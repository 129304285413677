.resource-image-url {
    height: 240px;
    width: 320px;
    border-radius: 5px;
    box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.5);
    cursor: pointer;
    transition: all 2s ease-in-out;
    object-fit: cover;
}

.resource-image-url:hover {
  transform: scale(0.95);
}

.details-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.7rem;
  padding-bottom: 0.1rem;
}

.resource-title {
  font-size: 1.8em;
  letter-spacing: 0.04rem;
  color: rgba(0, 0, 0, 0.87);
}

.text-description-container h4 {
  margin: 0;
  font-size: 0.95rem;
  margin-bottom: 0.2rem;
}

.text-description-container p {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 0.90rem;
  width: 60%;
}

.text-container {
  padding: 0.8rem;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  color: rgba(0, 0, 0, 0.87);
}

.text-description-container-resources {
  border: 1px solid #ccc;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  padding: 0.15rem;
}

.pdf-image-url {
  background: url("../../assets/pdfImage.jpg");
  background-size: cover;
  height: 100px;
  width: 100px;
  border-radius: 20px;
} 

.text-description-container-resources h4 {
  margin: 0 0 0.2rem;
  font-size: 0.95rem;
}

.text-description-container-resources p {
  margin: 0;
  font-size: 0.90rem;
  width: 60%;
}

.text-container-resource-details {
  padding: 0.8rem;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.87);
}

.text-container h4 {
    margin: 0;
    margin-bottom: 0.2rem;
    font-size: 0.95rem;
}

.text-container p {
    margin: 0;
    font-size: 0.90rem;
    width: 60%;
}

.presentation-image-text {
  font-weight: bold;
}

.resources-assets-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.text-container-overflow {
  overflow-y: scroll;
  height: 200px;
  border-radius: 5px;
}

.resource-edit-pdf-display-style {
  width: 350px;
}

.input-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.resource-upload-button-style {
  margin: 0;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  width: 200px;
  font: "PT Sans", Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  outline: none;
  cursor: pointer;
  color: #fff;
  background-color: var(--color-primary);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.input-style:hover {
  opacity: 0.9;
  background-color: #046a52;
}

.hidden-input-style {
  width: 50px;
}

.file-upload-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.resource-edit-upload-wrapper {
  margin-top: 1rem;
}

.select-wrapper {
  display: flex;
  margin-bottom: 3rem;
}

.select-spacer {
  margin-right: 8rem;
  width: 100px;
}

.video-style {
  border-radius: 5px;
}

.button-text-wrapper {
  width: 80%;
  margin-right: 90px;
}

.delete-button-style {
  width: 120px;
  border-radius: 5px;
  margin-top: 10px;
}

.asset-upload-button-style {
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.resource-form-text {
  margin-bottom: 10px;
}

.radiogroup-style {
  display: flex;
  flex-direction: row;
}

.resources-link-style {
  text-decoration: none;
}

.upload-paragraph-header {
  margin-bottom: 0;
}

.resource-complexity-level-content-type-container {
  margin: 1rem 0 2rem 0;
}

.resource-content-type-container {
  margin: 1rem 0 2rem 0;
}

.resource-edit-complexity-level-container {
  margin-left: 1rem;
}

.resource-edit-content-type-container {
  margin-left: 1rem;
}

.resource-complexity-level-content-type-container .resource-complexity-level-content-type-title,
.resource-content-type-container .resource-complexity-level-content-type-title {
  font-size: 1rem;
  letter-spacing: 0.04rem;
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
}

.resource-complexity-level-content-type-container .resource-complexity-level-content-type-title span,
.resource-content-type-container .resource-complexity-level-content-type-title span {
  font-weight: normal;
  font-size: 0.9rem;
}

@media screen and (max-width: 1400px) {
  .resources-assets-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1000px) {
  .resources-assets-container {
    grid-template-columns: 1fr;
  }

  .resource-uploaded-asset-container {
    margin: auto;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .delete-button-style {
      padding-right: 25px;
  }

  .button-text-wrapper {
    width: 90%;
  }
}

