.teacher-details-layout {
  margin: 10px;
  background-color: white;
  padding-bottom: 45px;
}

.teacher-cover {
  background-color: #068466;
  height: 150px;
}

.img-outer-container {
  padding-top: 45px;  
}

.img-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  border: white solid 8px;
  position: relative;
}

.teacher-img {
  border-radius: 50%;
  object-fit: cover;
  height: 200px;
  width: 200px;
}

.teacher-info {
  width: 50%;
  margin: auto
}

.teacher-data {
  display: flex;
  justify-content: center;
  align-items: center;
}

.teacher-name {
  margin-top: 120px;
}

.teacher-title {
  margin-top: 10px;
  font-size: 14px;
  color: rgb(112, 112, 112);
  text-transform: uppercase;
  letter-spacing: 4px;
}

.teacher-description {  
  margin-bottom: 30px;
  text-align: center;
  color: rgb(112, 112, 112);
  font-size: 16px;
}

.teacher-label {
  margin: 40px auto 15px auto;
  font-weight: 900;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media screen and (max-width: 1100px){
  .teacher-img {
    height: 175px;
    width: 175px;
  }
}

@media screen and (max-width: 1060px){
  .teacher-img {
    height: 150px;
    width: 150px;
  }
}

@media screen and (max-width: 800px){
  .teacher-img {
    height: 135px;
    width: 135px;
  }
}

@media screen and (max-width: 600px){
  .teacher-info {
    width: 85%;
  }

  .teacher-img {
    height: 200px;
    width: 200px;
  }
}