.module-title {
    font-size: 1.8em;
    letter-spacing: 0.04rem;
    color: rgba(0, 0, 0, 0.87);
  }

.surveys-wrapper {
    font-size: 18px;
    width: 100%;
}

.module-categories {
    margin-top: 0.3rem;
}

[contenteditable="true"].section-title br {
    display:none;
}

.module-edit-category-container {
    font-size: 1.3rem;
    padding-bottom: 0.5rem;
}

.module-file-upload-wrapper {
    display: flex;
    flex-direction: column;
    width: 60%;
    display: none;
  }
  @media screen and (max-width: 600px) {
    .module-file-upload-wrapper {
        width: 100%;
    }
}

.module-edit-image-header {
    padding-top: 0.8rem;
    margin-bottom: 0.8rem;
    font-size: 1.3rem;
}

.module-edit-image-filename {
    word-wrap: break-word;
    word-break: break-all;
}

.module-input-style {
    margin: 0;
    padding-top: 0.3rem;
    padding-left: 2.8rem;
    padding-bottom: 0.3rem;
    width: 200px;
    font: "PT Sans", Arial, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background-color: var(--color-primary);
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.surveys-table {
    padding: 10px;
}

.clickable {
    cursor: pointer;
    transition: background-color 250ms ease;
}

.clickable:active {
    background-color: #ddd !important;
}

.clickable:hover {
    background-color: #eeeeee;
}

.survey-question {
    margin: 10px;
    padding: 10px;
}

.survey-question-language {
    font-weight: bold;
    margin-bottom: 0;
}

.survey-question-text {
    margin-top: 0;
}

.survey-question-weight-categories {
    margin: 20px 0;
    display: block;
}

.text-container-module-details {
  padding: 0.8rem;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.87);
}

.text-description-container-modules {
    border: 1px solid #ccc;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    padding: 0.15rem;
}

.modules-tabs {
    background-color: white;
}

.text-description-container-modules h4 {
    margin: 0;
    margin-bottom: 0.2rem;
    font-size: 0.95rem;
}
  
.text-description-container-modules p {
    margin: 0;
    font-size: 0.90rem;
    width: 60%;
}

.text-container-overflow-module {
    border-radius: 5px;
}

.formcontrol-style {
    text-transform: capitalize;
}

.survey-form-header {
    display: flex;
}

.survey-form-text {
    margin-bottom: 10px;
}

.survey-form-weight {
    max-width: 80px;
    margin-right: 20px;
}

.module-details-header {
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0.7rem;
    padding-bottom: 0.1rem;
}

.module-details-image-style {
    height: 75%;
    width: 75%;
    max-height: 500px;
    max-width: 500px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.5);
    cursor: pointer;
    transition: all 2s ease-in-out;
}

.module-delete-button-style {
    width: 120px;
    border-radius: 5px;
    margin-top: 10px;
    display: none;
  }

@media screen and (max-width: 600px) {
    .module-delete-button-style {
        padding-right: 25px;
    }
}

.moduledetails-image-text {
    font-size: 18px;
}

button.error {
    color: #d32f2f;
}

.surveys-link-style {
    text-decoration: none;
}