.question-information-chart-wrapper {
  padding: 1rem 3rem 10rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.question-chart-header h4 {
  text-align: left;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  color: #2d2a34;
  margin-top: 16px;
  margin-bottom: 1rem;
}

.no-data-display-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.table-head {
  color: #3e3a3a;
  font-size: 11px;
  font-family: 'DM Sans';
  text-align: left;
  padding-right: 1rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  min-width: 3.5rem;
}

.table-data {
  color: #fff;
  font-family: 'DM Sans';
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  width: 115px;
  height: 40px;
}

.table-question-data {
  color: #797575;
  font-size: 13px;
  font-family: 'DM Sans';
  text-align: left;
  padding-right: 1rem;
}

.question-data-wrapper {
  margin-top: 1.5rem;
}

.question-statistics-information {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 4rem;
}

.question-statistics-info-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
}

.question-statistics-info-wrapper span:not(:last-child) {
  margin-right: 2rem;
}

.question-statistics-info-wrapper span {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}

.red-dot {
  background-color: rgb(238, 115, 98);
}

.yellow-dot {
  background-color: #FDCF5B;
}

.green-dot {
  background-color: rgb(6, 132, 102);
}

.red-dot,
.yellow-dot,
.green-dot {
  height: 8px;
  width: 8px;
  border-radius: 100px;
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: 0.0925rem;
  top: 50%;
}

.question-chart-bar-header {
  margin-bottom: 3rem;
}