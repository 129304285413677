.card-paper {
    padding: 1rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
}

.card-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #666;
    margin: 0;
}

.card-body {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    color: #4f5256;
    margin: 0;
}