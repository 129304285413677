.termDetails-wrapper {
    font-size: 18px;
    width: 100%;
}

.term-modules-wrapper {
    background-color: #fff;
    padding: 20px;
    margin: 10px;
}

.term-modules-table-container {
    border: 1px solid #000;
}

.termModules-title {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid #000;
}

.table-head-cell-style {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
    border-bottom: 1px solid #000;
}

/* Styling for error management */

.termdetails-errorDialog-title-style {
    color: #f44336;;
}

.termdetails-error-paragraph-style {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1.5rem;
}

.table-head-cell-style p {
    margin: 0;
    display: flex;
    align-items: center;
    padding-top: 0.2rem;
}

.termDetails-delete-button-style {
    width: 40%;
    min-width: 100px;
    display: flex;
    justify-content: center;
    margin: auto;
}

.table-order-style {
    border-right: 1px solid #000;
}

.term-module-delete-button {
    display: flex;
    justify-content: center;
}

.term-modules-table-head-style {
    border-bottom: 1px solid #000;
}

.table-cell-order-style {
    font-size: 1rem;
}

.table-cell-module-name {
    font-size: 1.05rem;
}

.term-module-category-button-container {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.paragraph-category-style {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 0.2rem;
    padding-bottom: 0.1rem;
}

.termCategory-title {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.2rem;
    margin-top: 0.2rem;
    margin-right: 1.5rem;
    margin-left: 0.5rem;
    font-weight: normal;
    font-weight: bold;
}

.term-add-icon-style {
    font-size: 1.1rem;
    margin-right: 0.5rem;
}

.termDetails-modal-button-alignment {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin: auto;
}

.termDetails-formcontrol {
    width: 90%;
    margin: auto;
    margin-bottom: 3rem;
}

.termDetails-modal-button-style {
    width: 50%;
    max-width: 10rem;
}

.termDetails-add-new-module {
    position: absolute;
    left: 400px;
}
.termTeacherRegister-button{
    float: right;
}

.teachersheading {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}


@media screen and (max-width: 950px) {
    .termDetails-add-new-module {
        left: 160px;
    }
}