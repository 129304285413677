.error-modal-message {
  color: #f40000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 1.2rem;
}

.missing-required-param-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 64px);
}


.missing-required-param-wrapper h4 {
  color: var(--color-error-message);
  background: rgba(0,0,0,0.25);
  padding: 1rem 2rem;
  border-radius: 3px;
}