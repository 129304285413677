.questions-container {
  background-color: rgb(243, 243, 243);
  min-height: calc(100vh - 247px);
}

.questions-container-inner-content {
  padding: 33px 46px 33px 46px;
}

.questions-overview-contanier {
  background-color: #fff;
  min-height: calc(100vh - 247px);
  width: 100%;
}

.dropdown-container {
  background-color: #fff;
  margin-top: 26px;
  display: flex;
  justify-content: center;
  padding: 1rem;
  padding-top: 2rem;
  padding-left: 3rem;
  width: 100%;
}

.select-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.select-container:first-child {
  margin-right: 5rem;
}

.dropdown-container p {
  font-size: 1rem;
  font-family: 'DM Sans';
  line-height: 20px;
  font-weight: bold;
  color: #3d4857;
  margin-right: 0.3rem;
}

.filtering-select {
  border: none;
  background-color: transparent;
  font-family: DM Sans;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  color: var(--color-primary);
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 2rem;
  -moz-appearance: none;
}

.filtering-option {
  font-family: DM Sans;
  font-weight: 700;
  color: #3d4857;
  border: none;
  font-size: 16px;
}