.activities-wrapper {
    font-size: 18px;
    width: 100%;
}

.activities-add-button-style {
    margin-bottom: 1rem;
}

.activities-toolbar {
  width: 100%;
  height: 81px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.upload-paragraph-header {
  margin-bottom: 0;
}

.activity-edit-pdf-display-style {
  width: 350px;
}

.asset-upload-button-style {
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.activity-edit-upload-wrapper {
  margin-top: 1rem;
}

.pdf-image-url {
  background: url("../../../assets/pdfImage.jpg");
  background-size: cover;
  height: 100px;
  width: 100px;
  border-radius: 20px;
}

.pdf-text {
  text-decoration: none;
}

.activity-edit-connected-material-label-wrapper {
  min-width: 200px;
  margin-top: 0.5rem;
  display: flex;
}

.activity-edit-connected-material-content-wrapper {
  margin-top: 1.5rem;
  display: inline-block;
}

.activity-title {
  font-size: 1.8em;
  letter-spacing: 0.04rem;
  color: rgba(0, 0, 0, 0.87);
}

.paper-activities-wrapper {
  margin: 10px;
  padding: 10px;
}

.activity-image-url {
  height: 240px;
  width: 320px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.75);
  cursor: pointer;
  transition: all 2s ease-in-out;
}

.presentation-image-text {
  font-weight: bold;
}

.activity-image-url:hover {
  transform: scale(0.95);
}

.text-container-activity-details {
  padding: 0.8rem;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.87);
}

.activities-assets-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.text-description-container-activities {
  border: 1px solid #ccc;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  padding: 0.15rem;
}

.text-description-container-activities h4 {
  margin: 0;
  margin-bottom: 0.2rem;
  font-size: 0.95rem;
}

.text-description-container-activities p {
  margin: 0;
  font-size: 0.90rem;
  width: 60%;
}

.activity-details-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.7rem;
  padding-bottom: 0.1rem;
}

.activity-form-text {
  margin-bottom: 10px;
}

.activity-file-upload-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.activity-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.activity-input-style {
  margin: 0;
  font: 1rem/1.5 "PT Sans", Arial, sans-serif;
  color: #5a5a5a;
  cursor: pointer;
  background-color: var(--color-primary);
  padding: 0.3rem;
  text-align: center;
  color: #fff;
  font-weight: bold;
  width: 200px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.activity-input-style:hover {
  opacity: 0.9;
  background-color: #046a52;
}

.activity-button-text-wrapper {
  width: 50%;
}

.activity-hidden-input-style {
  width: 50px;
}

.activity-delete-button-style {
  width: 120px;
  border-radius: 5px;
  margin-top: 10px;
}

.activity-select-spacer {
  margin-right: 8rem;
  min-width: 100px;
}

.edit-activity-form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
}

.activity-edit-instructions {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.activity-edit-details {
  margin: 10px;
  padding: 20px;
}

.activity-select-wrapper {
  display: flex;
  margin-bottom: 3rem;
}

.activities-link-style {
  text-decoration: none;
}

@media screen and (max-width: 1400px) {
  .activities-assets-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1000px) {
  .activities-assets-container {
    grid-template-columns: 1fr;
  }

  .activity-uploaded-asset-container {
    margin: auto;
    text-align: center;
  }
}

@media screen and (min-width: 600px) {
    .activities-wrapper {
      padding: 0;
    }
  }