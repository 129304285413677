.activity-delete-button-style {
    width: 120px;
    border-radius: 5px;
    margin-top: 10px;
}

.cycle-pdf-upload-wrapper {
    margin-top: 40px;
}

.cycle-upload-grid-container {
    flex-grow: 1;
}

.reinforced-delete-alert-style-cycle-pdf {
    color: #f44336;
    position: relative;
    transition: all 250ms ease;
    margin-top: 1rem;
    font-size: 1.2rem;
}
  
.file-upload-wrapper-cycle-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 2rem;
    box-shadow: 6px 5px 24px -28px rgba(0,0,0,0.75);
    margin-bottom: 2rem;
}

.upload-paragraph-header-cycle-pdf {
    margin-bottom: 0;
}

.asset-upload-button-style-cycle-pdf {
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cycle-pdf-edit-upload-wrapper {
    margin-top: 1rem;
}

.cycle-pdf-edit-pdf-display-style {
    width: 350px;
    height: 400px;
}

.cycle-upload-instructions {
    text-align: center;
    margin-bottom: 1rem;
    letter-spacing: 0.075rem;
    padding: 0 1rem;
    width: 75%;
}