.loading-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.loading-indicator {
    color: var(--color-primary);
}

.loading-text {
    font-family: "DM Sans", sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: var(--color-primary);
}

.small-loading-text {
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
    color: var(--color-primary);
}

