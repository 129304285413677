.micro-courses-link-style {
    text-decoration: none;
}

.update-create-micro-course-input-wrapper {
    margin: 10px;
    padding: 20px;
}

.micro-courses-select-wrapper {
    display: flex;
    margin-bottom: 3rem;
}

.micro-course-form-text {
    margin-bottom: 10px;
}

.micro-courses-create-edit-delete-button-style {
    width: 120px;
    border-radius: 5px;
    margin-top: 10px;
}

.micro-course-create-edit-upload-wrapper {
    margin-top: 1rem;
}

.micro-course-edit-create-uploaded-image {
    object-fit: cover;
}

/* Micro Course Details */
.microCourse-detail-title {
    font-size: 1.8em;
    letter-spacing: 0.04rem;
    color: rgba(0, 0, 0, 0.87);
}

.microCourse-details-link-style {
    text-decoration: none;
}

.micro-course-details-tabs {
    background-color: white;
}

.microCourse-form-control-style {
    text-transform: capitalize;
}

.text-description-container-microCourse-details {
    border: 1px solid #ccc;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    padding: 0.15rem;
}

.modules-tabs {
    background-color: white;
}

.text-description-container-microCourse-details h4 {
    margin: 0;
    margin-bottom: 0.2rem;
    font-size: 0.95rem;
}

.text-description-container-microCourse-details p {
    margin: 0;
    font-size: 0.90rem;
    width: 60%;
}

.text-container-overflow-microCourse-details {
    border-radius: 5px;
}

.text-container-microCourse-details {
    padding: 0.8rem;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.87);
}

.microCourse-details-image-style {
    height: 75%;
    width: 75%;
    max-height: 500px;
    max-width: 500px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.5);
    cursor: pointer;
    transition: all 2s ease-in-out;
}

.complexity-level-container, .content-type-container {
    margin: 1rem 0 2rem 0;
}

.complexity-level-container .complexity-level-title,
.content-type-container .content-type-title {
    font-size: 1rem;
    letter-spacing: 0.04rem;
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
}

.complexity-level-container .complexity-level-title span,
.content-type-container .content-type-title span {
    font-weight: normal;
    font-size: 0.9rem;
}

.micro-courses-select-type-container {
    margin-right: 2rem;
}

/* Micro course resources */
.micro-course-resources-add-button-style {
    margin-bottom: 1rem;
}