.companies-wrapper {
  font-size: 18px;
  width: 100%;
}

.company-details {
  margin: 10px;
  padding: 20px;
}

.company-details-multiline {
  font-size: 1.1rem;
}

.company-details-titlesOrDepartments {
  list-style: none;
  text-decoration: none;
  font-weight: bold;
}

.company-details-ul-style {
  padding-left: 0;
}

.company-details-list-alignment {
  display: flex;
}

.company-details-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.company-details-layout {
  display: flex;
  flex-flow: row wrap;
  padding: 20px;
  margin: 10px;
}

.company-details-section {
  flex: 1 0 33.333%;
  padding: 20px;
  margin: 20px;
  background: #f9f9f9;
  border: 2px solid #f0f0f1;
  box-shadow: -11px -11px 19px 0 rgba(255,255,255,0.19), 11px 11px 18px 0 rgba(0,0,0,0.05);
  border-radius: 16px;
}

.company-details-section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-details-linebreak {
  display: block;
  width: 100%;
  border-top: 1px solid #CCCCCC;
  margin-top: 10px;
  margin-bottom: 10px;
}

.company-tabs {
  background-color: white;
}

.MuiTabs-indicator {
  background-color: #068466;
}

.companies-table {
  padding: 10px;
}

.clickable {
  cursor: pointer;
  transition: background-color 250ms ease;
}

.clickable:active {
  background-color: #ddd !important;
}

.clickable:hover {
  background-color: #eeeeee;
}

.export-btn {
  font-family: "DM Sans", sans-serif;
  box-shadow: 0 2px 2px rgba(244, 67, 54, 0.14);
  float: right;
  top: 50px;
  right: 15px;
  color: #fff;
}

.company-result > img {
  width: 100%;
}

.company-name {
  margin: 20px 0;
  font-size: 36px;
  font-weight: normal;
}

[contenteditable="true"].company-name br {
  display: none;
}

.company-edit-category {
  max-width: 450px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.company-edit-category.list {
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.company-edit-category.list > .list-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.company-details-multiline {
  display: block;
}

.company-details-add_button {
  align-items: end;
}

.companies-link-style {
  text-decoration: none;
}

.companies-dialogTitle-warning-style {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f44336;
}

.companies-dialogText-warning-style {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-top: 0;
  letter-spacing: 0.05rem;
  font-weight: bold;
}

.company-details-counselingtext {
  list-style: none;
  margin-bottom: 1rem;
}

.company-details-counselingtext-span {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.delete-alert-style,
.reinforced-delete-alert-style {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  margin-top: 2rem;
}

.reinforced-delete-alert-style {
  color: #f44336;
  position: relative;
  transition: all 250ms ease;
}

.deny-delete-accept-style::before {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #f44336;
  transform: rotate(-45deg);
  position: absolute;
  border-radius: 5px;
}

.deny-delete-accept-style::after {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #f44336;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 5px;
}

@media (max-width: 800px) {
  .company-details-layout {
    flex-direction: column;
  }
}