.micro-course-resources-table-wrapper {
    flex: 1;
    box-sizing: border-box;
    position: relative;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    height: 100%;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
}

.micro-course-resources-table-wrapper .micro-course-resources-table-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.micro-course-resources-table-wrapper table {
    border-collapse: collapse;
    width: 100%;
}

.micro-course-resources-table-wrapper .micro-course-resource-table-row:hover td:not(:last-child) {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

.micro-course-resources-table-wrapper .micro-course-th, .micro-course-td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
    vertical-align: middle;
}

.micro-course-resources-table-wrapper .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 0.2rem;
    cursor: pointer;
    background: #fff;
}

.micro-course-resources-table-wrapper .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-bottom: 0.1rem;
}

.micro-course-resources-table-wrapper .up:active {
    transform: rotate(-135deg) scale(0.8);
    -webkit-transform: rotate(-135deg) scale(0.8);
}

.micro-course-resources-table-wrapper .up:hover {
    transform: rotate(-135deg) scale(1.15);
    -webkit-transform: rotate(-135deg) scale(1.15);
}

.micro-course-resources-table-wrapper .down {
    margin-top: 0.1rem;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.micro-course-resources-table-wrapper .down:active {
    transform: rotate(45deg) scale(0.8);
    -webkit-transform: rotate(45deg) scale(0.8);
}

.micro-course-resources-table-wrapper .down:hover {
    transform: rotate(45deg) scale(1.15);
    -webkit-transform: rotate(45deg) scale(1.15);
}

.micro-course-resources-table-wrapper .sort-arrow-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: none !important;
    width: 20px;
}

.micro-course-resources-table-wrapper .change-order-button-container {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

