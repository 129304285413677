.micro-course-resources-edit-create-details {
    margin: 10px;
    padding: 20px;
}

.micro-course-resources-edit-create-form-text {
    margin-bottom: 10px;
}

.micro-course-resources-edit-create-file-upload-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.micro-course-resources-edit-create-file-input-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.micro-course-resources-edit-create-upload-paragraph-header {
    margin-bottom: 0;
    min-height: 50px;
}

.micro-course-resources-edit-create-asset-upload-button-style {
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.micro-course-resources-edit-create-edit-upload-wrapper {
    margin-top: 1rem;
}

.micro-course-resources-edit-create-edit-button-text-wrapper {
    width: 80%;
    margin-right: 90px;
}

.micro-course-resources-edit-create-edit-reinforced-delete-alert-style {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    margin-top: 2rem;
    color: #f44336;
    position: relative;
    transition: all 250ms ease;
}

.micro-course-resources-edit-create-edit-delete-button-style {
    width: 120px;
    border-radius: 5px;
    margin-top: 10px;
}

.micro-course-resources-edit-create-edit-pdf-display-style {
    width: 350px;
}

@media screen and (max-width: 600px) {
    .micro-course-resources-edit-create-edit-delete-button-style {
        padding-right: 25px;
    }

    .micro-course-resources-edit-create-edit-button-text-wrapper {
        width: 90%;
    }
}

@media screen and (max-width: 700px) {
    .micro-course-resources-edit-create-upload-paragraph-header {
        min-height: 70px;
    }
}

/* Details */
.micro-course-resource-details-toolbar {
    width: 100%;
    height: 81px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.micro-course-resource-details-title {
    font-size: 1.8em;
    letter-spacing: 0.04rem;
    color: rgba(0, 0, 0, 0.87);
}

.micro-course-resource-details-link-style {
    text-decoration: none;
}

.micro-course-resource-details-text-description-container {
    border: 1px solid #ccc;
    margin-bottom: 1.5rem;
    border-radius: 5px;
    padding: 0.15rem;
}

.micro-course-resource-details-text-description-container h4 {
    margin: 0 0 0.2rem;
    font-size: 0.95rem;
}

.micro-course-resource-details-text-description-container p {
    margin: 0;
    font-size: 0.90rem;
    width: 60%;
}

.micro-course-resource-details-text-container {
    padding: 0.8rem;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.87);
}

.micro-course-resource-details-text-container-style {
    padding: 0.8rem;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    color: rgba(0, 0, 0, 0.87);
}

.micro-course-resource-details-presentation-image-text {
    font-weight: bold;
}

.micro-course-resource-details-image-url {
    height: 240px;
    width: 320px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.5);
    transition: all 2s ease-in-out;
}

.micro-course-resource-details-pdf-image-url {
    background: url("../../../assets/pdfImage.jpg");
    background-size: cover;
    height: 100px;
    width: 100px;
    border-radius: 20px;
    margin-top: 3rem;
}

.micro-course-resource-details-pdf-text {
    text-decoration: none;
}

.micro-course-resource-details-assets-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 1000px) {
    .micro-course-resource-details-assets-container {
        grid-template-columns: 1fr;
    }

    .micro-course-resource-details-uploaded-asset-container {
        margin: auto;
        text-align: center;
    }

    .micro-course-resource-details-pdf-image-url {
        margin: 3rem auto auto;
    }
}
