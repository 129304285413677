.cards-grid-container {
  flex-grow: 1;
}

.analysis-details {
  flex-grow: 1;
  margin-top: 15px;
  padding: 0 20px;
  min-height: 289px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.analysis-details-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  color: #2d2a34;
  margin-top: 1rem;
  margin-bottom: 0;
  padding-left: 0.5rem;
  padding-top: 0;
}

.chart-container {
  width: 80%;
  position: relative;
  min-height: 289px;
  height: 100%;
}

.to-few-results {
  height: 320px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.to-few-results p {
  font-size: 1.5rem;
  color: #3d4857;
  text-align: center;
  width: 100%;
}

.analysis-text-container {
  margin-top: 23px;
  margin-bottom: 22px;
  width: 35%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
  padding: 22px 10px 22px 26px;
  color: #666666;
  font-family: 'DM Sans';
}

.analysis-text-container h3 {
  font-size: 18px;
  font-weight: normal;
}

.analysis-text-container p {
  font-size: 13px;
  margin-top: 9px;
  line-height: 20px;
}

@media only screen and (max-width: 1930px) {
  .chart-container {
    width: 100%;
  }

  .analysis-details {
    flex-direction: column;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .analysis-text-container {
    margin: auto;
    margin-bottom: 22px;
    width: 94%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
  }
}