.distribution-bar-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  justify-content: left;
}

.distribution-bar-wrapper p {
  color: #797575;
  font-size: 13px;
  font-family: 'DM Sans';
  text-align: left;
  padding-right: 1rem;
  margin-bottom: 0.25rem;
  width: 40%;
  min-width: 200px;
  margin-top: 0.075rem;
}

.distribution-bar-info-wrapper {
  width: 60%;
  min-width: 500px;
}

.distribution-bar-percentage-wrapper {
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  margin-top: 0.5rem;
}

.distribution-bar-percentage-wrapper span {
  color: #4e4e4e;
  font-family: DM Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: right;
  z-index: 1000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.distribution-circle-red,
.distribution-circle-yellow,
.distribution-circle-green {
  height: 8px;
  width: 8px;
  display: inline-block;
  margin-left: 0.5rem;
  border-radius: 100px;
  margin-bottom: 0.135rem;
  margin-right: 0.25rem;
}

.distribution-circle-red {
  background-color: rgb(238, 115, 98);
}

.distribution-circle-yellow {
  background-color: #FDCF5B;
}

.distribution-circle-green {
  background-color: rgb(6, 132, 102);
}

.distribution-bar {
  height: 20px;
  min-width: 500px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  color: #343434;
}

.distribution-bar:first-child {
  margin-left: 0.5rem;
  text-shadow: 1px 0px #cccccc;
}

.distribution-bar:last-child {
  margin-right: 0.5rem;
  text-shadow: 1px 0px #cccccc;
}